import React, { useEffect, useState } from 'react'
import Datepicker from "react-tailwindcss-datepicker";
import DataTable from 'react-data-table-component';
import classNames from 'classnames';
import { APIURL } from '../config/config';
import {
    Button, Modal, Sidebar, Checkbox, Label, TextInput,
    Table, Select
} from 'flowbite-react';
import { HiArrowSmRight, HiChartPie, HiInbox, HiShoppingBag, HiTable, HiUser } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
// import axios from 'axios'

const weekdays = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
]

const columns = [
    {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
    },
    {
        name: 'Year',
        selector: row => row.year,
        sortable: true,
    },
];

const books_cols = [
    {
        name: "Service Name",
        selector: row => row.bookname,
        sortable: true,
    },
    {
        name: "Total Price",
        selector: row => row.Tprice,
        sortable: true,
    },
    {
        name: "Duration",
        selector: row => row.duration,
        sortable: true,
    },
    {
        name: "Time",
        selector: row => row.bookingTime,
        sortable: true,
    },
    {
        name: "Email",
        selector: row => row.email,
        sortable: true,
    },
    {
        name: "Name",
        selector: row => row.name,
        sortable: true,
    },
]

const slot_cols = [
    {
        name: "Start Time",
        selector: row => row.time,
        sortable: true,
    },
    {
        name: "Work on Saturday",
        selector: row => row.sat,
        sortable: true,
    },
    {
        name: "Work on Sunday",
        selector: row => row.sun,
        sortable: true,
    },
]

const data = [
    {
        id: 1,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 2,
        title: 'Ghostbusters',
        year: '1984',
    },
]
export const Admin = () => {

    const [page, setPage] = useState(0);
    const [slots, setsSlots] = useState([]);
    const [aways, setAways] = useState([]);
    const [createModal, setCreateModal] = useState(false);
    const [createModal2, setCreateModal2] = useState(false);
    const [createformData, setCreateFormData] = useState({
        hr: '00',
        min: '00',
        sat: false,
        sun: false
    });

    const [createformData1, setCreateFormData1] = useState({
        wd: '0',
        begin: '13',
        end: '15',
    });

    const [value3, setValue3] = useState({
        startDate: null,
        endDate: null
    });

    const handleValueChange3 = (newValue) => {
        console.log("newValue:", newValue);
        setValue3(newValue);
    }

    const [value, setValue] = useState({
        startDate: null,
        endDate: null
    });

    const [books, setBooks] = useState([]);

    const handleValueChange = async (newValue) => {
        console.log("newValue:", newValue);
        setValue(newValue);
        let response = await fetch(
            `${APIURL}/bookRange`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newValue)
            }
        );
        response = await response.json()
        console.log(response)
        setBooks(response)
    }

    const handlecreateChange = (e) => {
        let val = e.target.value;
        val = +val
        if (val < 10) {
            val = "0" + val
        }
        console.log(val)
        setCreateFormData({ ...createformData, [e.target.name]: val });
    };

    const handlecreateChange1 = (e) => {
        console.log({ [e.target.id]: e.target.value })
        setCreateFormData1({ ...createformData1, [e.target.id]: e.target.value });
        console.log(createformData1)
    };



    const handlecreateSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(createformData)
            const response = await fetch(
                `${APIURL}/slots`,
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(createformData)
                }
            );
            fetch(APIURL + "/slots").then(
                response => response.json()
            ).then(data => {
                console.log(data)
                data = data.sort((a, b) => a.hr - b.hr)
                setsSlots(data)
            })
            setCreateModal(false)
            console.log(response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handlecreateSubmit1 = async (e) => {
        e.preventDefault();
        try {
            console.log(createformData1)
            const response = await fetch(
                `${APIURL}/aways`,
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(createformData1)
                }
            );
            fetch(APIURL + "/aways").then(
                response => response.json()
            ).then(data => {
                console.log(data)
                data = data.sort((a, b) => a.wd - b.wd)
                setAways(data)
            })
            setCreateModal2(false)
            console.log(response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    const navClick = (id) => {
        setPage(id);

    }

    useEffect(() => {
        fetch(APIURL + "/slots").then(
            response => response.json()
        ).then(data => {
            console.log(data)
            data = data.sort((a, b) => a.hr - b.hr)
            setsSlots(data)
        })
        fetch(APIURL + "/book").then(
            response => response.json()
        ).then(data => {
            console.log(data)
            data = data.map(v => { return { ...v, name: v.firstName + ' ' + v.lastName } })
            setBooks(data)
        })
        fetch(APIURL + '/aways').then(
            response => response.json()
        ).then(data => {
            setAways(data)
        })
    }, [])

    return (
        <div className='flex'>
            <Sidebar className='h-screen' aria-label="Default sidebar example">
                <Sidebar.Items>
                    <Sidebar.ItemGroup>
                        <Sidebar.Item href="#" icon={HiChartPie} className={classNames("", { 'bg-gray-400': page === 0 })} onClick={e => {
                            navClick(0);
                        }}>
                            Bookings
                        </Sidebar.Item>

                        <Sidebar.Item href="#" icon={HiInbox} className={classNames("", { 'bg-gray-400': page === 1 })} onClick={e => {
                            navClick(1);
                        }}>
                            Slots
                        </Sidebar.Item>

                        <Sidebar.Item href="#" icon={HiShoppingBag} className={classNames("", { 'bg-gray-400': page === 2 })} onClick={e => {
                            navClick(2);
                        }}>
                            TimeAway
                        </Sidebar.Item>

                    </Sidebar.ItemGroup>
                </Sidebar.Items>

            </Sidebar>
            <div className='p-4'>
                {page === 2 && <>
                    <Table className='my-4'>
                        <Table.Head>
                            <Table.HeadCell>Id</Table.HeadCell>
                            <Table.HeadCell>From</Table.HeadCell>
                            <Table.HeadCell>To</Table.HeadCell>
                            <Table.HeadCell>Action</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {aways.map((away, id) => (
                                <Table.Row key={id}>
                                    <Table.Cell>{id}</Table.Cell>
                                    <Table.Cell>{away.startDate}</Table.Cell>
                                    <Table.Cell>{away.endDate}</Table.Cell>
                                    <Table.Cell>
                                        <Button>
                                            <MdDelete onClick={async () => {
                                                const response = await fetch(
                                                    `${APIURL}/aways`,
                                                    {
                                                        method: "DELETE",
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                        body: JSON.stringify({ id: away._id })
                                                    }
                                                );
                                                fetch(APIURL + "/aways").then(
                                                    response => response.json()
                                                ).then(data => {
                                                    console.log(data)
                                                    data = data.sort((a, b) => a.startDate - b.startDate)
                                                    setAways(data)
                                                })
                                            }} />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    <div className='flex'>
                        <div className='mt-4 w-full'>
                            <Datepicker
                                showShortcuts={true}
                                value={value3}
                                onChange={handleValueChange3}
                                popoverDirection="down"
                            />
                        </div>

                        <Button className='mt-4' onClick={async () => {
                            const response = await fetch(
                                `${APIURL}/aways`,
                                {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(value3)
                                }
                            );
                            fetch(APIURL + "/aways").then(
                                response => response.json()
                            ).then(data => {
                                console.log(data)
                                data = data.sort((a, b) => a.wd - b.wd)
                                setAways(data)
                            })
                        }}>Add</Button>
                    </div>

                    <Modal show={createModal2} onClose={() => setCreateModal2(false)}>
                        <Modal.Header>Create Away</Modal.Header>
                        <Modal.Body>
                            <form className="flex max-w-md flex-col gap-4">
                                {/* <div>
                                    <Datepicker
                                        showShortcuts={true}
                                        value={value}
                                        onChange={handleValueChange}
                                        popoverDirection="down"
                                    />
                                </div> */}
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="email1" value="Weekday" />
                                    </div>
                                    <Select id="wd" onChange={handlecreateChange1} required>
                                        <option value="0">Sun</option>
                                        <option value="1">Mon</option>
                                        <option value="2">Tue</option>
                                        <option value="3">Wed</option>
                                        <option value="4">Thu</option>
                                        <option value="5">Fri</option>
                                        <option value="6">Sat</option>
                                    </Select>
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="begin" value="Away Begin" />
                                    </div>
                                    <TextInput id="begin" type="number" placeholder="13" required
                                        name="min"
                                        onChange={handlecreateChange1}
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="end" value="Away End" />
                                    </div>
                                    <TextInput id="end" type="number" placeholder="15" required
                                        name="min"
                                        onChange={handlecreateChange1}
                                    />
                                </div>

                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handlecreateSubmit1}>Ok</Button>
                            <Button onClick={() => setCreateModal2(false)}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                </>}
                {page === 0 && <>
                    <div style={{ width: '500px' }}>
                        <Datepicker
                            showShortcuts={true}
                            value={value}
                            onChange={handleValueChange}
                            popoverDirection="down"
                        />
                    </div>
                    <div className="relative overflow-x-auto mt-4">
                        <DataTable
                            columns={books_cols}
                            data={books}
                        />

                    </div>
                </>}
                {page === 1 && <div>
                    <Table className='my-4'>
                        <Table.Head>
                            <Table.HeadCell>Id</Table.HeadCell>
                            <Table.HeadCell>Start Time</Table.HeadCell>
                            <Table.HeadCell>work on Saturday</Table.HeadCell>
                            <Table.HeadCell>work on Sunday</Table.HeadCell>
                            <Table.HeadCell>Action</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {slots.map((slot, id) => (
                                <Table.Row key={id}>
                                    <Table.Cell>{id}</Table.Cell>
                                    <Table.Cell>{`${slot.hr}:${slot.min}`}</Table.Cell>
                                    <Table.Cell>{(slot.sat) ? 'yes' : 'no'}</Table.Cell>
                                    <Table.Cell>{(slot.sun) ? 'yes' : 'no'}</Table.Cell>
                                    <Table.Cell>
                                        <Button>
                                            <MdDelete onClick={async () => {
                                                const response = await fetch(
                                                    `${APIURL}/slots`,
                                                    {
                                                        method: "DELETE",
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                        body: JSON.stringify({ id: slot._id })
                                                    }
                                                );
                                                fetch(APIURL + "/slots").then(
                                                    response => response.json()
                                                ).then(data => {
                                                    console.log(data)
                                                    data = data.sort((a, b) => a.hr - b.hr)
                                                    setsSlots(data)
                                                })
                                            }} />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    <Button className='mt-4' onClick={() => setCreateModal(!createModal)}>Add</Button>
                    <Modal show={createModal} onClose={() => setCreateModal(false)}>
                        <Modal.Header>Create Slot</Modal.Header>
                        <Modal.Body>
                            <form className="flex max-w-md flex-col gap-4">
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="email1" value="starting hour" />
                                    </div>
                                    <TextInput id="email1" name="hr" type="number" placeholder="16" required
                                        onChange={handlecreateChange}
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="password1" value="starting minute" />
                                    </div>
                                    <TextInput id="password1" type="number" placeholder="30" required
                                        name="min"
                                        onChange={handlecreateChange}
                                    />
                                </div>
                                <div className="flex items-center gap-2">
                                    <Checkbox id="sat"
                                        name='sat'
                                        checked={createformData.sat === true}
                                        onChange={handlecreateChange1}
                                    />
                                    <Label htmlFor="remember">available on Saturday</Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Checkbox id="sun"
                                        name='sun'
                                        checked={createformData.sun === true}
                                        onChange={handlecreateChange1}
                                    />
                                    <Label htmlFor="remember">available on Sunday</Label>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handlecreateSubmit}>Ok</Button>
                            <Button onClick={() => setCreateModal(false)}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                </div>}
            </div>

        </div>
    )
}
