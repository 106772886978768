import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AuthModal from '../components/AuthModal';
import Presention_img from '../assets/img/presentation.svg';
import { getAuthentication } from '../reducer/AuthenticationSlice';

import ContentImg1 from '../assets/img/f6a3d0_bc95c56662c44bf29fe915cd00119009~mv2.webp'
import ContentImg2 from '../assets/img/f6a3d0_bc95c56662c44bf29fe915cd00119009~mv2 (1).webp'
import ContentImg3 from '../assets/img/lips r_edited.webp'
import ContentImg4 from '../assets/img/nose_edited_edited.webp'
import ContentImg5 from '../assets/img/fat.webp'
import ContentImg6 from '../assets/img/botox 1.webp'
import HomeIntro from '../assets/img/home-intro.webp'
import OwnerImg from '../assets/img/owner.webp'
import ClientImg from '../assets/img/client.webp'

export default function Home() {
  const authentication = useSelector(getAuthentication);
  const [openModal, setOpenModal] = useState(false);
  const [showAuth, setShowAuth] = useState("signin");

  useEffect(() => {
    localStorage.getItem('token') ? setOpenModal(false) : setOpenModal(true);
  }, [authentication])

  const showSign = () => {
    if (showAuth == "signin") {
      setShowAuth("signup");
    } else {
      setShowAuth("signin");
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className='Cormorant'>
      <div className='bg-black'>
        <section className='container mx-auto grid grid-cols-1 lg:grid-cols-2 text-white'>
        
        <div className='flex justify-center items-center py-8'>
          <h2 className='text-[42px] text-center'><i>Are you ready to elevate your beauty?</i></h2>
        </div>
        
        <div className='lg:p-12 flex justify-center items-center'>
          <img src={HomeIntro}></img>
        </div>
      </section>
      </div>
      
      <section className='container mx-auto flex flex-col lg:flex-row text-black'>
        <div className='flex justify-center items-center p-4 w-full lg:w-3/5'>
          <h2 className='text-[20px] lg:text-[32px] text-center font-semibold lg:leading-[4vw]'><i>Welcome to Midnight Aesthetics.<br/>
We believe in celebrating beauty in all its forms. Our passion for aesthetics and dedication to
enhancing your unique beauty sets us apart. Whether you're looking to refresh your look, pamper
yourself, or explore the latest trends in beauty treatments, we are here to help you shine.<br/>
With a keen eye for detail and a commitment to excellence, we offer a range of personalized beauty
and aesthetic services tailored to meet your unique needs.<br/>
Rest assured, you are in safe hands. As a fully qualified and insured professional, I prioritize yoursafety and satisfaction above all else. Our tranquil and welcoming environment is designed to
provide a luxurious experience, allowing you to unwind and indulge in self-care.
Explore our services, discover your beauty potential, and let us be a part of your journey to looking
and feeling your best. We look forward to welcoming you soon at Midnight Aesthetics xx</i></h2>
        </div>
        
        <div className='lg:p-12 flex flex-col text-right w-full lg:w-2/5'>
          <img src={OwnerImg}></img>
          <p className='my-4'>(Catherine the owner)</p>
          <img src={ClientImg}></img>
          <p className='my-4'>(Catherine & one of our beautiful clients)</p>
        </div>
      </section>
      {/* <section>
        <div className="max-w-7xl text-center mx-auto p-6 lg:px-8">
          <h1 className='w-full text-[26px] sm:text-[36px]'>Subscribe for 10% off your first treatment with us!</h1>
        </div>
      </section>
      <section >
        <div className='relative max-w-7xl flex flex-col justify-center align-center sm:mx-auto lg:px-8 overflow-hidden'>
          <div className='flex justify-center w-full'>
            <img crossOrigin="anonymous" src={ContentImg1} alt='landing_banner2' className='w-full h-[860px] object-cover hidden md:flex' />
            <img crossOrigin="anonymous" src={ContentImg2} alt='landing_banner2' className='w-full h-[710px] object-cover md:hidden' />
          </div>
          <div className='w-full absolute bottom-[20px] text-center'>
            <span className='text-white text-[50px] md:text-[65px] font-cursive'>Hello Gorgeous! <br /> Welcome to Midnightaesthetic</span>
            <div className='flex justify-center sm:mt-5 font-sans_serif'>
              <p className='text-white w-2/3'>Midnightaesthetic London has everything you need to keep looking and feeling amazing every single day. From Dermal Fillers to Luxury Facials, treat yourself to one of our bookable pampering services today.</p>
            </div>
            <br />
            <div className='sm:mt-5 sm:mb-12 font-sans_serif flex justify-center'>
              <p className='text-white md:w-full sm:w-[550px] w-[270px] '>If your unsure of what treatment would be suitable please get in touch today via Instagram DM.</p>
            </div>
            <div className='sm:mt-5 mt-3 flex justify-center'>
              <div className=' w-full md:w-[630px] mx-5 bg-sans shadow-custom-shadow px-2 md:px-[150px] py-[10px] font-sans_serif font-bold'>
                <span>COVID VACCINE - It is advised not to have fillers two weeks before and three weeks after having the vaccine. Please be mindful of this when booking your treatment.</span>
                <br /><br />
                <span >BOOSTER Vaccine - Please leave 4 weeks either side before doing injectables.</span>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section>
        <div className="max-w-7xl mx-auto">
          <div className='w-full flex justify-center font-sans_serif text-[14px]'>
            <div className='flex w-full sm:w-[500px] lg:w-[980px] flex-wrap gap-3 justify-between'>
              <div className=' w-full sm:w-[230px] flex flex-col  text-center bg-pink px-5 pt-5 pb-[50px] mt-[-10px] mb-5 z-10 text-brown' >
                <img src={Presention_img} alt="Presention_img" className='w-[40px] h-[40px] mx-auto mt-5 mb-[40px]' />
                <p>" Lovely professional service, cant wait to come back for my top up x "</p>
              </div>
              <div className=' w-full sm:w-[230px] h-auto flex flex-col  text-center bg-pink px-5 pt-5 pb-[50px] mt-[-10px] mb-5 z-10 text-brown' >
                <img src={Presention_img} alt="Presention_img" className='w-[40px] h-[40px] mx-auto mt-5 mb-[40px]' />
                <p>"The best at doing lips!"</p>
              </div>
              <div className=' w-full sm:w-[230px] flex flex-col  text-center bg-pink px-5 pt-5 pb-[50px] mt-[-10px] mb-5 z-10 text-brown' >
                <img src={Presention_img} alt="Presention_img" className='w-[40px] h-[40px] mx-auto mt-5 mb-[40px]' />
                <p>"Very professional and friendly, absolutely love my lips and will definitely be back!!!x ."</p>
              </div>
              <div className=' w-full sm:w-[230px] flex flex-col  text-center bg-pink px-5 pt-5 pb-[50px] mt-[-10px] mb-5 z-10 text-brown' >
                <img src={Presention_img} alt="Presention_img" className='w-[40px] h-[40px] mx-auto mt-5 mb-[40px]' />
                <p>"Love my fillers so much they look amazing and your so lovely x."</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-pink'>
        <div className="max-w-7xl flex justify-center mx-auto mt-5">
          <div className='w-[523px] bg-white px-6 pt-6 mx-5'>
            <span className='text-[26px]'>Contact Us</span>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Write a message
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="bg-pink mt-5 px-12 py-3 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Submit
            </button>
          </div>

        </div>
        <div className='w-full flex justify-center mt-3'>
          <img crossOrigin="anonymous" src={ContentImg3} alt="" className='lg:h-[450px]' />
        </div>
        <div className='w-full flex justify-center mt-6'>
          <img crossOrigin="anonymous" src={ContentImg4} alt="" className='lg:h-[450px]' />
        </div>
        <div className='w-full flex justify-center mt-6'>
          <img crossOrigin="anonymous" src={ContentImg5} alt="" className='lg:h-[450px]' />
        </div>
        <div className='w-full flex justify-center mt-6'>
          <img crossOrigin="anonymous" src={ContentImg6} alt="" className='lg:h-[450px]' />
        </div>
      </section> */}
      <div className='px-5'>
        <AuthModal openModal={openModal} onClose={onCloseModal} showAuth={showAuth} showSign={showSign} />
      </div>




    </div>
  )
}