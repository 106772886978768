
const General = () => {

    return (
        <div className="bg-black text-white w-full relative flex justify-center pb-8">
            <div className=" max-w-[800px]  mx-auto w-full px-[20px]">
                <h1 className='w-full text-[36px] sm:text-[36px] mt-[50px] text-center md:text-start'>Policies</h1>
                <h2 className='w-full text-[26px] sm:text-[36px] mt-[50px] text-center md:text-start'>Covid-19</h2>
                <div className="text-[20px] leading-[50px] text-justify	">
                    <p className="font-bold mt-[60px]">BEFORE YOUR APPOINTMENT.</p>
                    <ol style={{listStyleType: 'decimal'}}>
                        <li>
                            If you feel you are at risk of being infected, even if you are asymptomatic, we kindly request for you to delay your appointment for a minimum of 2 weeks.
                        </li>
                        <li>Please wear a mask/face covering if possible. Staff will also be wearing a mask.</li>
                        <li>There are no plus ones or children allowed to your appointment.</li>
                        <li>Please try to use the toilet before your appointment to reduce the need at your appointment to reduce the risk of cross contamination.</li>
                        <li>Numbing cream will be used at your appointment! However please take a paracetamol 30-60 mins prior to your appointment (if not allergic). This is a great tip to help minimise any pain.</li>
                    </ol>
                    <p className="font-bold mt-[60px]">ON THE DAY OF YOUR APPOINTMENT.</p>
                    <ol style={{listStyleType: 'decimal'}}>
                        <li>
                        Please enter the premises no more than 5 minutes before your appointment. If you arrive early, please wait outside the building so we can prevent over crowding inside. If you arrive late, we may not have sufficient time to complete your treatment on the day.
                        </li>
                        <li>Please use the hand sanitiser provided.</li>
                        <li>There are no plus ones or children allowed to your appointment.</li>
                        <li>Please try to avoid using the toilet. If you do use it, please leave it as you would expect to find it.</li>
                    </ol>
                </div>
                <h2 className='w-full text-[26px] sm:text-[36px] mt-[50px] text-center md:text-start'>Cancellations</h2>
                <p className="mt-6 ">Every client’s time is valuable and we will do our utmost best to respect your time. Therefore, due to our commitment to providing the best possible experience for all of our patients, we have a strict 48 hour cancellation policy. A booking fee is required for all appointments. For cancellations within 48 hours of your appointment, the booking fee will be lost without refund & will not be able to be used for a new appointment. If there is a no show, the booking fee will be taken without refund and will not be able to be used to reschedule a new appointment. When booking with chic aesthetics, you agree to the terms and condition's.</p>
                <h2 className='w-full text-[26px] sm:text-[36px] mt-[50px] text-center md:text-start'>Lateness</h2>
                <p className="mt-6 ">We do our best to accommodate late arrivals however there is a late fee if your upto 15 minutes late, if you are more than 15 minutes late you will be turned away and your booking fee will be lost and cannot be used for a new appointment. Booking fees are also non refundable in the event of a no show. When booking with chic aesthetics, you agree to the terms and condition's.</p>
            </div>
        </div>
    )
}

export default General