import facebookIcon from '../assets/facebook-logo-button.svg';
import twitterIcon from '../assets/twitter.svg';
import instagramIcon from '../assets/instagram-fill.svg';
import tiktokIcon from '../assets/logo-tiktok.svg';

const Footer = () => {
  return (
    <>
      <div className='max-w-7xl mx-auto md:flex  justify-around pt-[58px] pb-[51px] p-6 '>
        <div className='md:w-[423px] w-full'>
          <p className="uppercase font-extrabold  text-md font-cursive_head">Midnightaesthetic London™</p>
          <p className="mt-5 capitalize  md:text-base text-sm">This website is owned and operated by Midnightaesthetic London. Midnightaesthetic London is owned and operated by CATHERINE-ROSE LEE.</p>
          <p className="font-bold  text-md">catherinerose@midnightaesthetics.co.uk</p>
        </div>
        <div className='mt-11   flex'>
          <a target="_blank" href='https://www.facebook.com/midnightaestheticsuk/'><img src={facebookIcon} alt="facebookIcon" className='mr-[27px]' /></a>
          {/* <img src={twitterIcon} alt="twitterIcon" className='mr-[27px]' /> */}
          <a target="_blank" href='https://www.instagram.com/midnight.aesthetics.uk/'><img src={instagramIcon} alt="instagramIconn" className='mr-[27px]' /></a>
          <a target="_blank" href='https://www.tiktok.com/@midnight.aestheti'><img width={21} height={21} src={tiktokIcon} alt="combineIcon" className='mr-[27px]' /></a>
        </div>
      </div>
    </>
  )
};

export default Footer;
