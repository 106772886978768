
const About = () => {

    return (
        <div className="bg-black text-white w-full relative flex justify-center pb-8">
            <div className=" max-w-[800px]  mx-auto w-full px-[20px]">
                <h1 className='w-full text-[36px] sm:text-[36px] mt-[50px] text-center md:text-start'>Before your treatment</h1>
                <div className="text-[20px] leading-[50px] text-justify	">
                    <p className="font-bold mt-[30px]">2 Days Prior to Injections</p>
                    <ol style={{listStyleType: 'disc'}}>
                        <li>
                        Avoid blood thinning over-the-counter medications such as aspirin, ibuprofen etc.
                        </li>
                        <li>Avoid supplements, including St. John’s Wort, Gingko biloba, primrose oil, garlic, ginseng, and Vitamin E.</li>
                        <li>Avoid waxing, bleaching, tweezing, or using hair removal cream on the area to be treated.</li>
                        <li>Do start taking Arnica two days prior to the procedure. (This is not required, but it will help to lessen bruising.)</li>
                    </ol>
                    <p className="font-bold mt-[30px]">24 Hours Prior to Injections</p>
                    <ol style={{listStyleType: 'disc'}}>
                        <li>
                        Do not drink alcohol 24 hours prior.
                        </li>
                        
                    </ol>
                    <p className="font-bold mt-[30px]">General Recommendations</p>
                    <ol style={{listStyleType: 'disc'}}>
                        <li>
                        If you have previously suffered from facial cold sores, there is a risk that the needle punctures could contribute to another eruption of cold sores due to the trauma. Please state beforehand if your suffer with the virus.
                        </li>
                        
                    </ol>
                </div>
                
                <h1 className='w-full text-[36px] sm:text-[36px] mt-[50px] text-center md:text-start'>Aftercare</h1>
                <div className="text-[20px] leading-[50px] text-justify	">
                    <p className="font-bold mt-[30px]">Immediately after your dermal filler treatment you may notice a few of the following things:</p>
                    <ol style={{listStyleType: 'disc'}}>
                        <li>
                        Swelling
                        </li>
                        <li>Bruising</li>
                        <li>Red needle puncture marks</li>
                        <li>Asymmetry</li>
                        <li>Tenderness</li>
                        <li>Itching in the area</li>
                        <li style={{listStyleType: 'none'}} className="my-8">These issues above are common with dermal filler aftercare, and should resolve over the next few days to weeks. Your final result will be seen at about 4 weeks after treatment.</li>
                        <li>Do not expose the area to intense heat (eg sauna or steam room)</li>
                        <li>Avoid pressure on the treated areas for the first few nights (i.e. sleep on back of possible)</li>
                        <li>Avoid Makeup for 24 hours.</li>
                        <li>Avoid strenuous exercise for 24 hours.</li>
                        <li>Avoid alcohol for 24 hours</li>
                        <li>Avoid facials, facial waxing, Glycolic or AHA peels, IPL or energy based treatments and microdermabrasion for two weeks after treatment.</li>
                        
                    </ol>
                    
                </div>
            </div>
        </div>
    )
}

export default About